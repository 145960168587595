import React from 'react';
import SEO from '../components/SEO';
import '../styles/scss/pages/404.scss';

const NotFoundPage = () => (
  <div className='page-not-found-container'>
    <SEO title='404: Not found' />
    <h1 className='page-not-found--header-text page-not-found--text'>Sorry...</h1>
    <p className='page-not-found--body-text page-not-found--text'>The video you are looking for<br /> cannot be found.</p>
  </div>
)

export default NotFoundPage
